





















































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
    name: 'About'
})
export default class extends Vue {
    private name = ''
    private email = ''
    private mess = ''

    onSendClicked() {
        // if(5 == 6) {
        //     return
        // }
        window.open(`mailto:support@thynkout.com?subject=Contact by ${this.name}&body=From:%20${this.email}%0A${this.mess}`);
        return
    }
}
