














































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Game from '@/app-components/game/index.vue'
import { IGameData } from '../../../api/types'
import {GamesData} from '@/api/data'
import ModalVideo from '@/app-components/game/ModalVideo.vue'

@Component({
    name: 'Games',
    components: {
      Game,
      ModalVideo
    }
})
export default class extends Vue {
  private games: Array<IGameData> = []

  async mounted() {
    this.games = await GamesData() as any
  }
    
  onPlayVideoClicked(gameData: IGameData) {
    (this.$refs['modalVideo'] as ModalVideo).show(gameData.videoPath || "")
  }
}
