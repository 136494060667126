










































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { IFeedbackData } from '../../../api/types'
import { FeedbacksData } from '../../../api/data'
import Feedback from '@/app-components/feedback/index.vue'

@Component({
    name: 'Feedbacks',
    components: {
      Feedback
    }
})
export default class extends Vue {
    private feedbacksData: Array<IFeedbackData> = FeedbacksData
}

